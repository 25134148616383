import React, { Component, useCallback, useEffect, useMemo, useState } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './vacantCOTAlerts.scss';

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'

// import {DeedPDFViewer} from './voidSalesAlertsSearch'
import {GoogleEvidence,AddressEvidence,YellComEvidence,CompaniesHouseEvidence,RightColumn, LeftColumn, FoodStandardsEvidence, BTDirectoryEvidence, CharityCommisionEvidence, DVSAEvidence, CQCEvidence, JustEatEvidence, One92Evidence, FacebookEvidence, TitleOwnerRow} from './sharedComponents'
import ProductIcons from '../../../helpers/productIcons'
import ReactModal from 'react-modal'
import PropertyMap from '../../../components/property-map/property-map'
import PageTitle from '../../../components/page-header/pageTitle'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'

export default function VacantCOTAlertsWorkflow() {
    const [accountNumber, setAccountNumber] = useState('')
    const [customer, setCustomer] = useState(null)
    const [showMap, setShowMap] = useState(true)
    const [purchasable, setPurchasable] = useState(null)
    const [workflowIndex, setWorkflowIndex] = useState(null)
    const [workflowLength, setWorkflowLength] = useState(0)
    const [disableActions, setDisableActions] = useState(false)
    const [translations, setTranslations] = useState({})
    const [exclusionReason, setExclusionReason] = useState('null')
    const [exclusionFeedback, setExclusionFeedback] = useState()
    const [workflowModalIsOpen, setWorkflowModalIsOpen] = useState('null')
    const [selectedWholesaler, setSelectedWholesaler] = useState(null)
    const [wholesalers, setWholesalers] = useState([])
    const [selectedAdcGroup, setSelectedAdcGroup] = useState('')
    const [selectedDate, setSelectedDate] = useState('')
    const [dates, setDates] = useState([])
    const [workflowItems, setWorkflowItems] = useState([])
    const [workflowCounts, setWorkflowCounts] = useState([])
    const [workflowStatusSetting, setWorkflowStatusSetting] = useState('New')
    const [status, setStatus] = useState('')
    const [loading, setLoading] = useState(false)
    
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        request(true).get('/translate?key=vacant_cot_alerts').then(r => {
            setTranslations(r.data)
        }).catch(e => {
            console.error(e);
        })
      
        request(true).get(endpoints.VACANT_COT_ALERTS_GET_WORKFLOW_DATES).then(r => {
            setDates(r.data.dates)
            request(true).get(`${endpoints.VACANT_COT_ALERTS_DASHBOARD_WHOLESALER}?user=${session.user.id}`).then(r => {
                setWholesalers(r.data)
                getWorkflowItems()
            });
        });
        
        getWorkflowCounts()
    }, [])
    
    const getData = useCallback(() => {
        setCustomer(null)
        setPurchasable(null)
        request(true).get(`${endpoints.VACANT_COT_ALERTS_GET_CUSTOMER}?search=${accountNumber}`).then(r => {
            setCustomer(r.data.customer)
            setStatus(r.data.customer.status)
            setShowMap(true)
            setPurchasable(r.data.customer && r.data.customer.Date_Bought === null)
            setDisableActions(false)
        }).catch(e => {
            //console.log(e);
        });
    }, [accountNumber])

    const purchaseCustomer = (occupierPurchaseType) => {
        setDisableActions(true)
        request(true).post(`${endpoints.VACANT_COT_ALERTS_BUY_CUSTOMER}`, {
            "customer": customer.SPID,
            "occupier_purchase_type": occupierPurchaseType,
        }).then(res => {
            setWorkflowModalIsOpen('null')
            refreshCounts()
        }).catch(e => {
            console.log(e);
        })
    }

    const openWorkflowBuyModal = () => {
        setWorkflowModalIsOpen('true')
    }

    const excludeCustomer = () => {
        if (!customer || !customer.SPID) return;
        setDisableActions(true)
        request(true).post(endpoints.VACANT_COT_ALERTS_EXCLUDE_CUSTOMER, {
            customer: customer.SPID,
            Occupier_Exclusion_Reason: exclusionReason,
            feedback: exclusionFeedback
        }).then(r => {
            const customers = [].concat(workflowItems);
            customers.splice(workflowIndex, 1);

            setWorkflowItems(customers)
            setWorkflowLength(customers.length)
            setAccountNumber(customers[workflowIndex])
            setExclusionReason('null')

            getData();
        }).catch(e => {
            console.error(e);
        });
    }
    
    let _cur = useMemo(() => {
        return workflowItems.findIndex(_ => _ === accountNumber);
    }, [workflowItems, accountNumber]);

    const getWorkflowItems = useCallback(() => {
        setLoading(true)
        request(true).get(endpoints.VACANT_COT_ALERTS_WF_ITEMS, {
            doesCancel: true,
            params: {
                status: workflowStatusSetting,
                selectedWholesaler: selectedWholesaler,
                selectedAdcGroup: selectedAdcGroup,
                selectedDate: selectedDate,
            }
        }).then(e => {
            setWorkflowItems(e.data)
            if (e.data.length > 0) {
                setAccountNumber(e.data[_cur && _cur !== -1 ? _cur : 0])
            } else {
                setAccountNumber()
            }
            setLoading(false)
        }).catch(e => {
            console.log(e);
            window.alert('Could not retrieve data')
            setLoading(false)
        })
    }, [_cur, workflowStatusSetting, selectedAdcGroup, selectedDate, selectedWholesaler])
    
    useEffect(() => {
        getWorkflowCounts()
    }, [])
    
    useEffect(() => {
        _cur = 0
        setWorkflowIndex(0)
        getWorkflowItems()
    }, [workflowStatusSetting])

    useEffect(() => {
        _cur = 0
        setWorkflowIndex(0)
        refreshCounts()
    }, [selectedAdcGroup, selectedDate, selectedWholesaler])
    
    useEffect(() => {
        getData()
    }, [accountNumber])

    const getWorkflowCounts = () => {
        request(true).get(endpoints.VACANT_COT_ALERTS_WF_COUNTS, {
            selectedWholesaler: selectedWholesaler,
            selectedAdcGroup: selectedAdcGroup,
            selectedDate: selectedDate,
        }).then(r => {
            setWorkflowCounts(r.data)
        })
    }

    const workflowNext = () => {
        if (_cur !== workflowItems.length - 1) {
            if (customer.status !== status) {
                refreshCounts()
                return
            }
            setWorkflowIndex(workflowIndex + 1)
            setAccountNumber(workflowItems[_cur + 1])
        }
    }
    
    const workflowPrev = () => {
        if (_cur !== 0) {
            if (customer.status !== status) {
                refreshCounts()
                return
            }
            setWorkflowIndex(workflowIndex - 1)
            setAccountNumber(workflowItems[_cur - 1])
        }
    }

    const refreshCounts = () => {
        getWorkflowItems()
        getWorkflowCounts()
    }

    const updateStatus = (event) => {
        request(true).post(`${endpoints.VACANT_COT_ALERTS_UPDATE_STATUS}`, {
            customer: customer.SPID,
            status: event.target.value
        }).catch(e => {
            console.log(e);
        })
        setCustomer({
            ...customer,
            status: event.target.value
        })
    }

    const setAdcGroup = (event) => {
        setSelectedAdcGroup(event.target.value)
        setCustomer(null)
        setAccountNumber('')
    }

    const setWholesaler = (event) => {
        setSelectedWholesaler(event.target.value)
    }

    const setFileDate = (event) => {
        console.log("setting file date to: " + event.target.value)
        setSelectedDate(event.target.value)
    }
    
    const statuses = ["New", "In Progress", "Complex", "Awaiting Customer", "Awaiting Visit"];

    return (
        <div className="grid grid-gap-15" id="void-sales-alerts-page">
            <Navigation />
            <Container>
                <PageTitle title={`${translations['module_title'] || ''} - Workflow`} iconClass={ProductIcons.BusinessIcon.props.className} titleRight={
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Link className="navigate" to="/portal/vacant-cot-alerts/">
                            To Overview
                        </Link>
                        <Link className="navigate" to="/portal/vacant-cot-alerts/search">
                            To Search
                        </Link>
                        {(hasPermission('VACANT_COT', 'DASHBOARD')) ?
                            <Link className="navigate" to="/portal/vacant-cot-alerts/dashboard">
                                To Dashboard
                            </Link>
                            : null}
                    </div>
                } />
            </Container>
            <Container>
                <div className="vacantCOTAlerts">
                    <div style={{ display: 'grid', gap: '10px', gridTemplateColumns: `repeat(${statuses.length}, auto)`, margin: '0em 5em' }}>
                        {
                            statuses.map(statusName => {
                                const count = workflowCounts.find(wfCount => wfCount?.status?.toLowerCase() === statusName.toLowerCase());
                                return (
                                    <button className={`button compact smaller-text ${workflowStatusSetting === statusName ? "bulk-buy-button" : "background-primary"} colour-white`} onClick={() => {
                                        refreshCounts()
                                        setWorkflowStatusSetting(statusName)
                                        // getData()
                                    }}>
                                        {statusName} ({count?.count ?? 0})
                                    </button>
                                )
                            })
                        }
                    </div>
                    <div className="primary-grid">
                        <div className="left-grid">
                            <div className="data-row with-buttons">
                                <div className="data-row-title">
                                    {translations ? translations['spid'] : ''}
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <input disabled={true} type="text" className="data-row-field" name="account-number" value={loading ? '' : accountNumber} style={{ width: 'calc(100% - 15px)', height: '100%' }} />
                                    {loading && <i className="fa fa-spinner fa-spin" style={{ position: 'absolute', top: '10px', left: '10px' }} />}
                                    <span className="message">{workflowIndex + 1}/{workflowItems.length}</span>
                                </div>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '15px', marginLeft: '15px' }}>
                                    <button className="button background-primary colour-white" onClick={workflowPrev} disabled={workflowIndex === 0}>
                                        <i className="fas fa-angle-left"></i>
                                    </button>
                                    <button className="button background-primary colour-white" onClick={workflowNext} disabled={workflowIndex === workflowLength - 1}>
                                        <i className="fas fa-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                            <LeftColumn state={{translations, customer}} />
                        </div>
                        <RightColumn state={{workflow: workflowItems, selectedDate, dates, translations, customer, selectedAdcGroup, selectedWholesaler, wholesalers}} setAdcGroup={setAdcGroup} setWholesaler={setWholesaler} setFileDate={setFileDate} >
                            {(!hasPermission('VACANT_COT', 'PURCHASE') || !purchasable) ?
                                <button className="buy-button disabled" disabled={true}>
                                    Occupier Moved In
                                </button>
                                :
                                <button className="buy-button" disabled={!purchasable || disableActions} onClick={openWorkflowBuyModal}>
                                    {
                                        customer && customer.Purchase_Type === 'VaCA_ACCOUNT_PURCHASED' ? <>
                                            {
                                                customer.Bought_By ?
                                                    `Moved in on ${customer.Date_Bought} by ${customer.Bought_By}`
                                                    :
                                                    customer.Date_Bought ?
                                                        `Move in on ${customer.Date_Bought}` : ''
                                            }
                                        </> : customer && customer.Purchase_Type === 'VaCA_ACCOUNT_PURCHASED' ? <>
                                            Excluded
                                        </> : <>
                                            {
                                                purchasable === true || purchasable === null ? "Occupier Moved In" : 'Unavailable for Move in'
                                            }
                                        </>
                                    }

                                </button>
                            }
                            {/* <button className="buy-button" onClick={this.excludeCustomer} disabled={ disableActions }>
                                    Occupier Excluded
                                </button> */}
                            <select style={{ height: 28, marginTop: 7, border: '1px solid var(--primary)', borderRadius: 5 }} onChange={_ => setExclusionReason(_.target.value)} value={exclusionReason}>
                                <option value={'null'}>Select an exclusion reason</option>
                                <option>Existing Live Account</option>
                                <option>Multiple Occupier/LL Query</option>
                                <option>Address/Data Quality Issue</option>
                                <option>Previous Occupier Provided</option>
                                <option>Adjacent Occupier Provided</option>
                                <option>De-registration</option>
                                <option>Account Already Open - Same Occupier</option>
                                <option>Account Already Open - Different Occupier</option>
                                <option>Other Retailer</option>
                                <option>No Consumption</option>
                                <option>Insufficient Information</option>
                                <option>Other</option>
                            </select>
                            <div className="data-row">
                                <div className="data-row-title">
                                    Status
                                </div>
                                <select style={{ height: 28, border: '1px solid var(--primary)', borderRadius: 5 }} onChange={updateStatus} value={customer?.status ?? "New"}>
                                    <option value="New">New</option>
                                    <option value="Complex">Complex</option>
                                    <option value="In progress">In progress</option>
                                    <option value="Awaiting Customer">Awaiting Customer</option>
                                    <option value="Awaiting Visit">Awaiting Visit</option>
                                </select>
                            </div>
                        </RightColumn>
                        <TitleOwnerRow state={{translations, customer}} />

                    </div>
                    <div className="secondary-grid">
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%', marginBottom: 5 }}>
                            <GoogleEvidence data={customer} />
                            <CompaniesHouseEvidence data={customer} />
                            <YellComEvidence data={customer} />
                            <AddressEvidence data={customer} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%' }}>
                            <FoodStandardsEvidence data={customer} />
                            <BTDirectoryEvidence data={customer} />
                            <CharityCommisionEvidence data={customer} />
                            <DVSAEvidence data={customer} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%' }}>
                            <CQCEvidence data={customer} />
                            <JustEatEvidence data={customer} />
                            <One92Evidence data={customer} />
                            <FacebookEvidence data={customer} />
                        </div>
                    </div>
                    <div className="secondary-grid" style={{ gridTemplateColumns: '1fr 3fr', gridGap: '15px', gap: '15px' }}>
                        <table className="table borders squish super-small-text extra-row-padding">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'left' }}>
                                        Days
                                    </th>
                                    <th>
                                        Opening Times
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(day => {
                                        return (
                                            <tr>
                                                <td style={{ textAlign: "left", width: "100px" }}>{day}</td>
                                                <td style={{ minWidth: "unset" }}>{customer?.Google_Opening_Hours ? JSON.parse(customer.Google_Opening_Hours.replaceAll('\'', '"'))[day] : null}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <div style={{ height: 'auto' }}>
                            {showMap ?
                                <PropertyMap address={customer ? customer.SPID_Address : null} />
                                :
                                <>
                                    <div className="map hidden" onClick={() => { if (customer) { setShowMap(true) } }}>
                                        {customer ? 'Click to show map' : ''}
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </Container>
            <HelpVideo video={session.company.vacant_workflow_video_url} />
            <ReactModal
                className="card bulk-buy"
                isOpen={exclusionReason !== 'null'}>
                <p>You have selected the following exclusion reason:</p>
                <p><strong>{exclusionReason}</strong></p>
                <div style={{ display: 'grid', marginBottom: '1em' }}>
                    <p>Additional Feedback:</p>
                    <textarea onChange={(event) => setExclusionFeedback(event.target.value.slice(0, 500))} value={exclusionFeedback ?? ''} rows={5} style={{ resize: 'none' }}/>
                    <div style={{ marginLeft: 'auto', color: 'gray', fontSize: 12 }}>
                    {exclusionFeedback?.length ?? 0}/500
                    </div>
                </div>
                <div className='grid grid-columns-2 grid-gap-5'>
                    <button className='button compact background-red colour-white' onClick={() => setExclusionReason(null)}>Cancel</button>
                    <button className='button compact background-primary colour-white' onClick={() => excludeCustomer()}>Confirm</button>
                </div>
            </ReactModal>
            <ReactModal
                className="card bulk-buy"
                isOpen={workflowModalIsOpen !== 'null'}>
                <p>You have selected to move in an occupier </p>
                <p>Select either Cancel, IDenteq for identified name or Other for an other name </p>

                <div className='grid grid-columns-3 grid-gap-5'>
                    <button className='button compact background-red colour-white' onClick={() => setWorkflowModalIsOpen('null')}>Cancel</button>
                    {session.company.vaca_confirmation_screen === 1 ?
                        <button
                            className='button compact background-primary colour-white'
                            onClick={() => {
                                console.log('Source Component - onClick', customer?.SPID, location);

                                history.push({
                                    pathname: `/portal/vacant-cot-alerts/confirmation/`,
                                    state: { spid: customer?.SPID, index: workflowIndex, wholesaler: selectedWholesaler, adcGroup: selectedAdcGroup, date: selectedDate, prevPath: location.pathname }
                                })
                            }
                            }
                        >
                            Identeq
                        </button>
                        :
                        <button className='button compact background-primary colour-white' onClick={() => purchaseCustomer('IDenteq')}>IDenteq</button>
                    }
                    <button className='button compact background-primary colour-white' onClick={() => purchaseCustomer('Other')}>Other</button>
                </div>
            </ReactModal>
            <Footer />
        </div>
    )
}
